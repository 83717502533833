// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-post-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/templates/post.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-post-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-page-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/templates/page.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-page-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-tag-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/templates/tag.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-tag-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-posts-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/templates/posts.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-templates-posts-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-404-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-404-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-archive-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/pages/archive.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-archive-tsx" */),
  "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-tags-tsx": () => import("/zeit/19dbfd0a/node_modules/@nehalist/gatsby-theme-nehalem/src/pages/tags.tsx" /* webpackChunkName: "component---node-modules-nehalist-gatsby-theme-nehalem-src-pages-tags-tsx" */)
}

